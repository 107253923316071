import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Homepage,
  Packages,
  About,
  Contact,
  PackageInfo,
  Blog,
  PageNotFound,
  PaymentSuccess,
} from "./pages";
import NavBar from "./components/NavBar/NavBar";
import { FacebookPixel, CookieConsent } from "./components";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useState } from "react";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Currency } from "./types";
import React from 'react';

import enTranslation from "./locales/en.json";
import deTranslation from "./locales/de.json";
import itTranslation from "./locales/it.json";
import frTranslation from "./locales/fr.json";
import esTranslation from "./locales/es.json";

function setCookie(key: string, value: string, days: number = 90): void {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    key + "=" + encodeURIComponent(value) + "; expires=" + expires + "; path=/";
}

// Function to get a cookie
function getCookie(key: string): string {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === key ? decodeURIComponent(parts[1]) : r;
  }, "");
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      de: {
        translation: deTranslation,
      },
      it: {
        translation: itTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

interface PageRoute {
  path: string;
  element: React.ReactElement;
  name: string;
}

const pages: PageRoute[] = [
  {
    path: "/",
    element: <Homepage />,
    name: "Home",
  },
  {
    path: "/packages",
    element: <Packages />,
    name: "Packages",
  },
  {
    path: "/about",
    element: <About />,
    name: "About",
  },
  {
    path: "/blog",
    element: <Blog />,
    name: "Blog",
  },
  {
    path: "/contact",
    element: <Contact />,
    name: "Contact",
  },
  {
    path: "/success",
    element: <PaymentSuccess />,
    name: "PaymentSuccess",
  },
  {
    path: "/packages/:id",
    element: <PackageInfo />,
    name: "Package",
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    name: "PrivacyPolicy",
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
    name: "TermsOfService",
  },
  {
    path: "*",
    element: <PageNotFound />,
    name: "404",
  },
];

const router = createBrowserRouter(pages);

function App(): React.ReactElement {
  const getDefaultCurrency = (): Currency => {
    const savedCurrency = getCookie("currency");
    if (savedCurrency && ["EUR", "USD", "GBP", "CHF"].includes(savedCurrency)) {
      return savedCurrency as Currency;
    }
    
    if (i18n.language.includes("en")) {
      return "GBP";
    } else if (i18n.language.includes("de") || 
               i18n.language.includes("it") ||
               i18n.language.includes("fr") ||
               i18n.language.includes("es")) {
      return "EUR";
    } else {
      return "USD";
    }
  };
  
  const [currency, setCurrency] = useState<Currency>(getDefaultCurrency());
  
  const handleCurrencyChange = (curr: Currency): void => {
    setCookie("currency", curr);
    setCurrency(curr);
    i18n.changeLanguage(i18n.language.split("-")[0]);
  };

  useEffect(() => {
    handleCurrencyChange(getDefaultCurrency());
  }, []);

  return (
    <div className="App">
      <FacebookPixel />
      <div style={{ position: "relative" }}>
        <NavBar 
          language={i18n.language.split("-")[0]}
          currency={currency} 
          setLanguage={(lng: string) => i18n.changeLanguage(lng)}
          setCurrency={handleCurrencyChange}
        />
        <RouterProvider router={router} />
      </div>
      <CookieConsent />
    </div>
  );
}

export default App; 
