import React, { useEffect, useState } from "react";
import { Button, trackFbPixelEvent } from "../components";
import { useTranslation } from "react-i18next";

// Check if backend URL is configured
if (!process.env.REACT_APP_BACKEND_URL) {
  console.error('Backend URL is not configured');
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';

const PaymentSuccess: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [purchaseData, setPurchaseData] = useState<any>(null);

  useEffect(() => {
    const verifyPayment = async (): Promise<void> => {
      try {
        // Get the session ID from URL
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get("session_id");

        if (!sessionId) {
          setError("No session ID found");
          setIsLoading(false);
          return;
        }

        const response = await fetch(`${BACKEND_URL}/api/process-payment?sessionId=${sessionId}`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to process payment');
        }

        const result = await response.json();

        if (!result.success) {
          throw new Error('Payment processing failed');
        }

        // Store the purchase data for use in tracking
        setPurchaseData(result.data || {});
        
        // Track the purchase completion with Facebook Pixel
        trackFbPixelEvent('Purchase', {
          content_name: result.data?.packageName || 'Package',
          content_ids: [result.data?.packageId || ''],
          content_type: 'product',
          value: result.data?.amount || 0,
          currency: result.data?.currency || 'USD',
          num_items: result.data?.quantity || 1,
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error processing payment:", error);
        console.error("Error details:", {
          name: error instanceof Error ? error.name : "Unknown error",
          message: error instanceof Error ? error.message : String(error),
          stack: error instanceof Error ? error.stack : undefined
        });
        setError("Error processing your payment");
        setIsLoading(false);
      }
    };

    verifyPayment();
  }, []);

  return (
    <>
      <main
        className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
        style={{
          height: "100vh",
          backgroundImage:
            "url(https://images.pexels.com/photos/1109967/pexels-photo-1109967.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)",
        }}
      >
        <div className="text-center">
          {isLoading ? (
            <p className="text-2xl font-semibold">Loading...</p>
          ) : error ? (
            <>
              <p className="text-2xl font-semibold text-red-600">{error}</p>
              <div className="mt-10">
                <Button href="/">{t("go_back_home")}</Button>
              </div>
            </>
          ) : (
            <>
              <p
                className="text-9xl font-semibold mb-8"
                style={{ color: "#daf561" }}
              >
                &#10003;
              </p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {t("payment_success")}
              </h1>
              <p className="mt-6 text-base leading-7 text-gray-600">
                {t("thank_you_for_your_purchase")}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button href="/">{t("go_back_home")}</Button>
                <a href="/orders" className="text-sm font-semibold text-gray-900">
                  {t("contact_us_low")} <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default PaymentSuccess; 
