import React from "react";
import Atropos from "atropos/react";
import "atropos/css/min";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Package } from "../../types";

import countryCodes from "../../countryCodes.json";

// Type for the country codes object
type CountryCodes = {
  [code: string]: string;
};

const getCountryCode = (country?: string): string => {
  if (!country) return "";
  
  const codes = countryCodes as CountryCodes;
  for (let code in codes) {
    if (codes[code]?.toLowerCase() === country.toLowerCase()) {
      return code;
    }
  }
  return "";
};

// Helper function to create URL-friendly slug from package name
const createSlug = (name: string): string => {
  // Define accented character mappings
  const charMap: Record<string, string> = {
    'á': 'a', 'à': 'a', 'ä': 'a', 'â': 'a', 'ã': 'a',
    'é': 'e', 'è': 'e', 'ë': 'e', 'ê': 'e',
    'í': 'i', 'ì': 'i', 'ï': 'i', 'î': 'i',
    'ó': 'o', 'ò': 'o', 'ö': 'o', 'ô': 'o', 'õ': 'o',
    'ú': 'u', 'ù': 'u', 'ü': 'u', 'û': 'u',
    'ñ': 'n', 'ç': 'c',
    'ß': 'ss', 'Æ': 'AE', 'æ': 'ae'
  };
  
  // Transliterate characters
  const transliterated = name.toLowerCase().split('').map(char => {
    return charMap[char] || char;
  }).join('');
  
  return transliterated
    .replace(/[^\w\s-]/g, '') // Remove remaining special characters
    .replace(/\s+/g, '-')     // Replace spaces with hyphens
    .trim();
};

interface PackageCardProps {
  destinationPackage: Package;
  dark?: boolean;
  onClick?: () => void;
}

const PackageCard: React.FC<PackageCardProps> = ({
  destinationPackage = {
    id: "",
    name: "",
    price: 0,
    continent: "",
    nation: "",
    imageURL: "",
    description: "",
    type: "",
    status: "",
    currency: "USD"
  },
  dark = true,
  onClick = null,
}) => {
  const { t } = useTranslation();

  return (
    <Atropos
      activeOffset={
        destinationPackage?.status?.toLowerCase() === "work in progress"
          ? 5
          : 20
      }
      shadowScale={
        destinationPackage?.status?.toLowerCase() === "work in progress"
          ? 0.8
          : 0.85
      }
      rotateTouch="scroll-y"
      {...{} as any} // Type assertion for Atropos props
    >
      <div
        className="rounded-xl flex flex-col cursor-pointer atropos-scale atropos-rotate bg-white"
        style={{
          backgroundColor: dark ? "#3451A3" : "#fff",
          cursor:
            destinationPackage.status === "Work in Progress"
              ? "inherit"
              : "pointer",
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (destinationPackage.status !== "Work in Progress") {
            // Generate URL-friendly slug from name
            const slug = createSlug(destinationPackage.name);
            window.location.href = `/packages/${slug}`;
          }
        }}
      >
        <div
          className="p-0 m-0 w-full h-96 bg-cover bg-center rounded-t-lg"
          style={{
            maxHeight: "200px",
            backgroundImage: `url(${destinationPackage.imageURL})`,
            opacity: destinationPackage.status === "Work in Progress" ? 0.4 : 1,
          }}
        >
          {destinationPackage.status === "Work in Progress" && (
            <h2
              className="text-black absolute w-full text-center font-bold opacity-100"
              style={{
                fontSize: "44px",
                top: "20%",
                transform: "rotate(-10deg)",
              }}
            >
              {t("coming_soon")}
            </h2>
          )}
        </div>
        <div
          className="rounded-b-lg p-6 flex flex-col gap-2 md:gap-0 space-between justify-between"
          style={{
            backgroundColor: dark ? "#3451A3" : "#fff",
            opacity: destinationPackage.status === "Work in Progress" ? 0.4 : 1,
          }}
        >
          <div className="flex justify-between" data-atropos-offset="2.5">
            <h3
              className="text-xl font-bold -mt-2 mb-2 text-left"
              style={{ color: dark ? "white" : "#132441" }}
            >
              {destinationPackage.name}
            </h3>
            <div className="flex flex-row gap-2">
              <div
                className="opacity-70"
                style={{
                  color: dark ? "white" : "#132441",
                  textAlign: "right",
                  width: "fit-content",
                }}
              >
                {destinationPackage.continent.toUpperCase()}
              </div>
              {destinationPackage.nation !== "" && (
                <div
                  style={{
                    height: "13px",
                    width: "13px",
                    borderRadius: "10px",
                    backgroundImage: `url(https://flagcdn.com/h40/${getCountryCode(
                      destinationPackage.nation
                    )}.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginTop: "-1px",
                    opacity: 0.9,
                  }}
                />
              )}
            </div>
          </div>
          <p
            className="subheading text-sm"
            style={{
              color: dark ? "#DAF561" : "#132441",
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
            data-atropos-offset="2.5"
          >
            {destinationPackage[`subtitle_${i18n.language.split("-")[0]}`]}
          </p>
        </div>
      </div>
    </Atropos>
  );
};

export default PackageCard; 
