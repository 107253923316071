// Use CommonJS require syntax for Stripe
const Stripe = require('stripe');

// Define interfaces for function parameters and return types
interface StripeSessionOptions {
  packageId: string;
  packageName: string;
  packagePrice: number;
  currency: string;
  quantity?: number;
  stepsDescription?: string;
  versionId: string;
  versionName: string;
  couponId?: string | null;
}

// Define return type for the createStripeSession function
interface StripeSessionResponse {
  url: string;
  id: string;
  [key: string]: any;
}

// Initialize Stripe with your API key
const stripe = Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY || '');

// Define baseUrl in a browser-safe way
const baseUrl = typeof window !== 'undefined' 
  ? `${window.location.protocol}//${window.location.host}/`
  : 'http://localhost:3000/';

/**
 * Creates a Stripe checkout session
 * @param options Session options containing all required data
 * @returns Promise that resolves to the session response
 */
export const createStripeSession = async ({
  packageId,
  packageName,
  packagePrice,
  currency,
  quantity = 1,
  stepsDescription = '',
  versionId,
  versionName,
  couponId = null,
}: StripeSessionOptions): Promise<StripeSessionResponse> => {
  try {
    // For testing purposes, if we don't have an API key, return a mock response
    if (!process.env.REACT_APP_STRIPE_SECRET_KEY) {
      console.warn('No Stripe API key found, returning mock response');
      return {
        url: 'https://example.com/checkout-success',
        id: 'mock_session_id',
      };
    }

    // Create the session
    const session = await stripe.checkout.sessions.create({
      line_items: [
        {
          price_data: {
            currency: currency.toLowerCase(),
            product_data: {
              name: `${packageName} - ${versionName} - Deposit`,
              description:
                "30% deposit due now, the remainder will be charged 6 weeks prior to the start. Free cancellation until then.",
              images: [], // No image URLs as they're not in our interface
            },
            unit_amount: Math.round(packagePrice * 100 * 0.3),
          },
          quantity: quantity,
        },
      ],
      mode: 'payment',
      success_url: `${baseUrl}success?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: typeof window !== 'undefined' ? window.location.href : baseUrl,
      metadata: {
        packageId: packageId,
        packageName: packageName,
        totalAmount: packagePrice.toString(),
        depositAmount: (packagePrice * 0.3).toString(),
        quantity: quantity.toString(),
        versionId: versionId,
        versionName: versionName,
        stepsDescription: stepsDescription,
        couponId: couponId || '',
      },
    });

    return {
      url: session.url || '',
      id: session.id,
      ...session,
    };
  } catch (error) {
    console.error('Error creating Stripe session:', error);
    throw error;
  }
}; 
