import { useEffect } from 'react';

// Facebook Pixel ID
const FACEBOOK_PIXEL_ID = '487250187750863';

// Define the fbq function type properly
type FbqFunction = {
  (command: string, pixelId: string, eventData?: any, options?: any): void;
  callMethod?: (...args: any[]) => void;
  queue?: any[];
  push?: any;
  loaded?: boolean;
  version?: string;
};

// Declare fbq as a global function with proper typing
declare global {
  interface Window {
    fbq: FbqFunction;
    _fbq: FbqFunction;
  }
}

const FacebookPixel: React.FC = () => {
  useEffect(() => {
    // Skip if already initialized
    if (window.fbq) return;

    // Initialize Facebook Pixel with proper typing
    const fbq = function(...args: any[]) {
      // @ts-ignore - We handle this dynamically
      fbq.callMethod ? fbq.callMethod.apply(fbq, args) : fbq.queue.push(args);
    } as FbqFunction;

    window.fbq = fbq;
    if (!window._fbq) window._fbq = fbq;
    fbq.push = fbq;
    fbq.loaded = true;
    fbq.version = '2.0';
    fbq.queue = [];

    // Create and load script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    
    const firstScript = document.getElementsByTagName('script')[0];
    if (firstScript && firstScript.parentNode) {
      firstScript.parentNode.insertBefore(script, firstScript);
    } else {
      document.head.appendChild(script);
    }

    // Initialize pixel with ID and track pageview
    fbq('init', FACEBOOK_PIXEL_ID);
    fbq('track', 'PageView');

    // Create noscript fallback
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    img.src = `https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`;
    noscript.appendChild(img);
    document.body.appendChild(noscript);

    // Cleanup on unmount
    return () => {
      // Only remove the noscript element we added
      document.body.querySelectorAll('noscript').forEach(ns => {
        if (ns.innerHTML.includes(FACEBOOK_PIXEL_ID)) {
          ns.remove();
        }
      });
    };
  }, []);

  return null; // This component doesn't render anything
};

export default FacebookPixel;

// Helper function to track custom events (can be exported and used throughout the app)
export const trackFbPixelEvent = (event: string, params?: any): void => {
  if (window.fbq) {
    window.fbq('track', event, params);
  }
}; 
