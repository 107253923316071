import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CookieConsent.css';

type CookieSettings = {
  necessary: boolean;
  preferences: boolean;
  analytics: boolean;
  advertising: boolean;
};

// This is used to set cookies with an expiration date
const setCookie = (name: string, value: string, days: number = 365): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

// This is used to get cookie values
const getCookie = (name: string): string => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
};

const CookieConsent: React.FC = () => {
  const { t } = useTranslation();
  const [showConsent, setShowConsent] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [settings, setSettings] = useState<CookieSettings>({
    necessary: true, // Always true and non-toggleable
    preferences: false,
    analytics: false,
    advertising: false
  });

  // Check if the consent has been given already
  useEffect(() => {
    const consentGiven = getCookie('cookieConsent');
    if (!consentGiven) {
      setShowConsent(true);
    } else {
      // If consent was previously given, restore those settings
      try {
        const savedSettings = JSON.parse(consentGiven);
        setSettings(savedSettings);
        applySettings(savedSettings);
      } catch (e) {
        // If there's an error parsing, just use default settings
        setShowConsent(true);
      }
    }
  }, []);

  // This function applies the cookie settings
  const applySettings = (currentSettings: CookieSettings): void => {
    // Always set necessary cookies
    // Manage Facebook Pixel based on analytics and advertising consent
    if (currentSettings.analytics && currentSettings.advertising) {
      // Enable Facebook Pixel
      if (window.fbq) {
        window.fbq('consent', 'grant');
      }
    } else {
      // Disable Facebook Pixel
      if (window.fbq) {
        window.fbq('consent', 'revoke');
      }
    }

    // Save settings to a cookie
    setCookie('cookieConsent', JSON.stringify(currentSettings));
  };

  const handleAcceptAll = (): void => {
    const allAccepted = {
      necessary: true,
      preferences: true,
      analytics: true,
      advertising: true
    };
    setSettings(allAccepted);
    applySettings(allAccepted);
    setShowConsent(false);
  };

  const handleRejectNonEssential = (): void => {
    const essentialOnly = {
      necessary: true,
      preferences: false,
      analytics: false,
      advertising: false
    };
    setSettings(essentialOnly);
    applySettings(essentialOnly);
    setShowConsent(false);
  };

  const handleCustomize = (): void => {
    setShowSettings(true);
  };

  const handleSaveSettings = (): void => {
    applySettings(settings);
    setShowSettings(false);
    setShowConsent(false);
  };

  const toggleSetting = (setting: keyof CookieSettings): void => {
    if (setting === 'necessary') return; // Cannot toggle necessary cookies
    setSettings({
      ...settings,
      [setting]: !settings[setting]
    });
  };

  // Function to open the cookie settings again
  const openCookieSettings = (): void => {
    setShowConsent(true);
    setShowSettings(true);
  };

  // Add to window to make it accessible globally
  useEffect(() => {
    (window as any).openCookieSettings = openCookieSettings;
  }, []);

  if (!showConsent) return null;

  return (
    <div className="cookie-consent-container">
      <div className="cookie-consent-wrapper">
        {!showSettings ? (
          <div className="cookie-consent-banner">
            <div className="cookie-consent-content">
              <h2>{t('cookie_consent.title', 'Cookie Consent')}</h2>
              <p>
                {t('cookie_consent.description', 
                   'XPeaks GmbH uses cookies to enhance your browsing experience, analyze site traffic, and personalize content. We may also share information about your site usage with our analytics and advertising partners. For more information about how we use cookies and your data, please see our ')}
                {' '}<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                  {t('cookie_consent.privacy_policy', 'Privacy Policy')}
                </a>{' '}.
              </p>
            </div>
            <div className="cookie-consent-actions">
              <button 
                className="cookie-button primary" 
                onClick={handleAcceptAll}
              >
                {t('cookie_consent.accept_all', 'Accept All')}
              </button>
              <button 
                className="cookie-button secondary" 
                onClick={handleRejectNonEssential}
              >
                {t('cookie_consent.essential_only', 'Essential Only')}
              </button>
              <button 
                className="cookie-button secondary" 
                onClick={handleCustomize}
              >
                {t('cookie_consent.customize', 'Customize')}
              </button>
            </div>
          </div>
        ) : (
          <div className="cookie-settings">
            <h2>{t('cookie_consent.settings_title', 'Cookie Settings')}</h2>
            <div className="settings-options">
              <div className="setting-item">
                <div className="setting-header">
                  <label>
                    <input 
                      type="checkbox" 
                      checked={settings.necessary} 
                      disabled
                    />
                    <span>{t('cookie_consent.necessary', 'Necessary')}</span>
                  </label>
                </div>
                <p>{t('cookie_consent.necessary_desc', 'Essential cookies enable core functionality like page navigation and access to secure areas. The website cannot function properly without these cookies.')}</p>
              </div>
              
              <div className="setting-item">
                <div className="setting-header">
                  <label>
                    <input 
                      type="checkbox" 
                      checked={settings.preferences} 
                      onChange={() => toggleSetting('preferences')}
                    />
                    <span>{t('cookie_consent.preferences', 'Preferences')}</span>
                  </label>
                </div>
                <p>{t('cookie_consent.preferences_desc', 'Preference cookies enable the website to remember information that changes the way the website behaves or looks, like your preferred language or the region you are in.')}</p>
              </div>
              
              <div className="setting-item">
                <div className="setting-header">
                  <label>
                    <input 
                      type="checkbox" 
                      checked={settings.analytics} 
                      onChange={() => toggleSetting('analytics')}
                    />
                    <span>{t('cookie_consent.analytics', 'Analytics')}</span>
                  </label>
                </div>
                <p>{t('cookie_consent.analytics_desc', 'Analytics cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This helps us improve our website and services.')}</p>
              </div>
              
              <div className="setting-item">
                <div className="setting-header">
                  <label>
                    <input 
                      type="checkbox" 
                      checked={settings.advertising} 
                      onChange={() => toggleSetting('advertising')}
                    />
                    <span>{t('cookie_consent.advertising', 'Advertising')}</span>
                  </label>
                </div>
                <p>{t('cookie_consent.advertising_desc', 'Advertising cookies are used to deliver relevant advertisements and track the performance of advertising campaigns. These may include Facebook Pixel which helps us display relevant content to you on Facebook platforms.')}</p>
              </div>
            </div>
            <div className="cookie-consent-actions settings-actions">
              <button 
                className="cookie-button primary" 
                onClick={handleSaveSettings}
              >
                {t('cookie_consent.save', 'Save Settings')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CookieConsent; 