import React from "react";
import Atropos from "atropos/react";
import "atropos/css/min";
import "./CarouselImage.css";

// Import AtroposProps type from the module definition
// The type is defined in src/types/custom.d.ts
interface CarouselImageProps {
  image: string;
}

const CarouselImage: React.FC<CarouselImageProps> = ({ image }) => {
  return (
    <div
      className="container"
      style={{ width: "25vw", minWidth: "300px", height: "50vh" }}
    >
      {/* 
       * Using 'as any' to bypass type checking for now
       * The Atropos component from the library accepts these props at runtime
       * but TypeScript definitions may not be up-to-date
       */}
      <Atropos
        className="my-atropos h-full"
        rotateTouch="scroll-x"
        activeOffset={5}
        shadowScale={0.85}
        shadowOpacity={0.5}
        {...{} as any}
      >
        <div className="atropos h-full">
          <div className="atropos-scale h-full">
            <div className="atropos-rotate h-full">
              <div className="atropos-inner h-full">
                <img
                  className="atropos-spacer"
                  src={image}
                  alt="carousel image"
                  style={{ display: "none" }}
                />
                <img
                  data-atropos-offset="-5.5"
                  src={image}
                  alt="carousel image"
                  className="h-full"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="atropos-shadow"></div>
            </div>
          </div>
        </div>
      </Atropos>
    </div>
  );
};

export default CarouselImage; 
