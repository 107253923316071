import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy" style={{ padding: '8rem', backgroundColor: "#132441", color: 'white' }}>
            <p style={{ fontWeight: 'bold', fontSize: '2rem' }}>Privacy Policy</p>
            <p>
                Welcome to our Adventure Packages service. Your privacy is important to us, and we are committed to protecting your personal information. This privacy policy outlines the types of information we collect, how we use it, and the measures we take to safeguard it.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Information We Collect</p>
            <p>
                We collect personal information that you provide to us directly, such as your name, email address, phone number, and payment details when you book an adventure package. We may also collect information about your preferences and interests to better serve you.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>How We Use Your Information</p>
            <p>
                We use the information we collect to provide our services, process bookings, communicate with you, and improve your experience. We may also use your information to send you promotional materials and updates about our services if you have opted in to receive such communications.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Sharing Your Information</p>
            <p>
                We do not share your personal information with third parties except as necessary to provide our services, comply with legal obligations, or protect our rights. For example, we may share your information with trusted partners who help us manage bookings and payments.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Data Security</p>
            <p>
                We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or misuse. However, please note that no method of transmission over the internet is 100% secure.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Your Rights</p>
            <p>
                You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us at info@x-peaks.com.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Changes to This Policy</p>
            <p>
                We may update this privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically.
            </p>

            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Contact Us</p>
            <p>
                If you have any questions or concerns about our privacy practices, please contact us at info@x-peaks.com.
            </p>
        </div>
    );
};

export default PrivacyPolicy; 
