import React, { useState, useRef, useEffect } from "react";
import CarouselImage from "./CarouselImage";

interface CarouselOnScrollProps {
  images: string[];
}

const CarouselOnScroll: React.FC<CarouselOnScrollProps> = ({ images }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const autoScrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!carouselRef.current) return;
    
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);

    // Disable text selection while dragging
    document.body.style.userSelect = "none";
    // Stop auto-scrolling when user starts dragging
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (!isDragging || !carouselRef.current) return;
    e.preventDefault();
    
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Adjusted for smoother scroll speed
    
    requestAnimationFrame(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft = scrollLeft - walk;
      }
    });
  };

  const stopDragging = (): void => {
    setIsDragging(false);
    document.body.style.userSelect = "auto";
    startAutoScroll();
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    if (!carouselRef.current) return;
    
    setIsDragging(true);
    setStartX(e.touches[0].pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
    
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    if (!isDragging || !carouselRef.current) return;
    
    const x = e.touches[0].pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 1.2; // Lower multiplier for mobile
    
    requestAnimationFrame(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft = scrollLeft - walk;
      }
    });
  };

  const handleTouchEnd = (): void => {
    setIsDragging(false);
    startAutoScroll();
  };

  // Function to start the auto-scroll
  const startAutoScroll = (): void => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
    }
    
    autoScrollIntervalRef.current = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft += 1;
      }
    }, 10);
  };

  // Function to stop the auto-scroll
  const stopAutoScroll = (): void => {
    if (autoScrollIntervalRef.current) {
      clearInterval(autoScrollIntervalRef.current);
    }
  };

  // Start auto-scrolling when the component mounts
  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, []);

  return (
    <div
      ref={carouselRef}
      className="carousel-container"
      style={{
        display: "flex",
        overflowX: "scroll",
        overflowY: "hidden",
        gap: "16px",
        paddingTop: "128px",
        paddingBottom: "128px",
        marginTop: "-128px",
        marginBottom: "-128px",
        cursor: isDragging ? "grabbing" : "grab",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {images.map((image, index) => (
        <CarouselImage key={index} image={image} />
      ))}
    </div>
  );
};

export default CarouselOnScroll; 
